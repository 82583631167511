import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";


const PageHeader = ({ title, returnNav }) => (
  <Row className="page-header">
    <Col className="col-md-10">
      <div className='header-return-nav'>
        { returnNav }
      </div>
      <h2>{title}</h2>
    </Col>
  </Row>
);

export default PageHeader;
