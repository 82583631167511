import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import redwood from "../../images/redwood2.gif";

const PageFooter = ({ title }) => {
    return (
        <Row className='page-footer'>
          <Col className='col-12'>
            <img src={redwood}/>
          </Col>
        </Row>
    );
}

export default PageFooter;
