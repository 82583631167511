import React from "react";
import { Link, graphql } from "gatsby";
import Dinero from 'dinero.js';

import AniLink from "gatsby-plugin-transition-link/AniLink";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Layout from "../components/layout";
import Image from "../components/image";
import SEO from "../components/seo";

import PageHeader from "../components/page/header";
import PageDivider from "../components/page/divider";
import PageFooter from "../components/page/footer";

import "./registry.scss";

const RegistryThanks= ({ data }) => {

  return (
    <Layout page="registry">
      <SEO title="Registry" />

      <PageHeader title="Thank You!" />

      <Row className="page-content">
        <Col />
        <Col className="col-md-10">We're super thankful for your generous gift!</Col>
        <Col />
      </Row>

      <PageFooter />
    </Layout>
  );
};

export default RegistryThanks;
