import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import redwood from "../../images/redwood2.gif";

const PageDivider = ({ title }) => {
    return (
        <Row className='page-divider'>
            <Col className='col-12' style={{textAlign: 'center'}}>
              <img src={redwood} style={{width: '20px'}}/>
            </Col>
        </Row>
    );
}

export default PageDivider;
